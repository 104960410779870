import React from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import MissingCompData from 'components/MissingCompData';
import { AskingRentGraph } from 'components/Graphs/AskingRentGraph';
import { AvailabilityDonutGraph } from 'components/Graphs/AvailabilityDonutGraph';
import SpinnerLoader from 'components/SpinnerLoader';
import { I18N_PROPERTY_LABEL_PATH } from 'components/PropertyProfile/constants';
import {
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PROPERTY_TEXT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { ColorNames } from 'constants/colorNames';
import { DASH_PLACEHOLDER } from 'constants/placeholders';
import { UnitOfMeasurement } from 'constants/unitOfMeasurement';
import locations from 'routes';
import { GET_PROPERTY_AVAILABILITY_SPACE_DATA } from 'graphql/property';
import { getCurrentYearExpense, isDataCenterBuilding } from 'utils/properties';
import {
  currencyInputFormatter,
  getCurrencySymbol,
} from 'utils/formatters/currency';
import { formatNumberWithDecimals } from 'utils/formatters/number';
import { formatArea } from 'utils/formatters/area';
import { translateText } from 'utils/i18n';

import { useAvailabilitiesOverTimeWide } from 'hooks/useAvailabilitiesOverTimeWide';

import ComparativeCard from '../../ComparativeCard';
import { Column } from '../../constants';
import RowSeparator from '../ColumnInformation/RowSeparator';
import InformationRow from '../ColumnInformation/InformationRow';
import commonStyles from '../ComparativeColumns.module.scss';

const AvailabilityColumn: React.FC<Column> = ({
  columnTheme,
  property,
  completeCompetitiveProperties,
  toggleOptions,
}) => {
  const { dataCenter } = property;

  const propertiesIds = completeCompetitiveProperties!.map(
    property => property.id,
  ) as number[];

  const currencyCode =
    property?.propertyCountry?.currencyCode ?? property?.currencyCode ?? 'USD';

  const {
    availabilities,
    loadingAvailabilitiesTimeWide,
    getBuildingAskingRent,
    getMarketAskingRent,
  } = useAvailabilitiesOverTimeWide({
    propertiesIds,
    toggleOptions,
  });
  const hasPropertyAvailabilities = availabilities?.some(
    a => a.propertyId === property.id,
  );

  const customMissingText = !hasPropertyAvailabilities
    ? translateText(
        `${I18N_AVANT_PROPERTY_TEXT_PATH}.notFound.noAvailabilities`,
      )
    : '';

  const { data, loading } = useQuery(GET_PROPERTY_AVAILABILITY_SPACE_DATA, {
    variables: { propertyId: property.id },
  });
  const { totalAvailable, totalDirectAvailableSf, totalSubletAvailableSf } =
    data?.availabilitySpace || {};

  const getBottomRows = () => {
    let costsRow = DASH_PLACEHOLDER;
    if (isDataCenterBuilding(property)) {
      const currencySymbol = getCurrencySymbol(
        property.propertyCountry?.currencyCode || property.currencyCode,
      );
      costsRow =
        dataCenter?.costPerKwhMin && dataCenter.costPerKwhMax
          ? `Cost Per kWH ${currencySymbol}${formatNumberWithDecimals(
              dataCenter?.costPerKwhMin,
            )} min - ${currencySymbol}${formatNumberWithDecimals(
              dataCenter.costPerKwhMax,
            )} max`
          : DASH_PLACEHOLDER;
    } else {
      const { taxAndOpex, year } = getCurrentYearExpense(property) || {};

      if (taxAndOpex && year) {
        const currencySymbol = getCurrencySymbol(
          property.propertyCountry?.currencyCode || property.currencyCode,
        );
        const formattedTaxAndOpex = formatNumberWithDecimals(
          currencyInputFormatter(taxAndOpex + ''),
        );

        costsRow = `${currencySymbol} ${formattedTaxAndOpex} OpEx & Taxes`;
      } else {
        costsRow = DASH_PLACEHOLDER;
      }
    }

    const company = isDataCenterBuilding(property)
      ? dataCenter?.powerProviderCompany?.name
      : property.leasingCompany?.name;
    const label = isDataCenterBuilding(property)
      ? translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.powerProvider`,
        )
      : translateText('avantPlatform.roles.company.label.leasedBy');

    const companyElement =
      company && property.leasingCompany?.id ? (
        <Link
          to={locations.showCompany(property.leasingCompany?.id)}
          className={classnames(
            commonStyles.tenant,
            commonStyles['company-link'],
          )}
        >
          {`${label} ${company}`}
        </Link>
      ) : (
        DASH_PLACEHOLDER
      );

    return (
      <>
        <InformationRow>
          <span className={commonStyles['text-info']}>{costsRow}</span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={commonStyles['text-info']}>{companyElement}</span>
        </InformationRow>
      </>
    );
  };

  if (loading || loadingAvailabilitiesTimeWide) {
    return (
      <div className={commonStyles['loading-content']}>
        <SpinnerLoader background={ColorNames.ayTotalBlackColor} />
      </div>
    );
  }

  return (
    <ComparativeCard theme={columnTheme}>
      <>
        {!isNil(totalAvailable) ? (
          <AvailabilityDonutGraph totalAvailability={totalAvailable * 100} />
        ) : (
          <MissingCompData type="availability" property={property} />
        )}
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={commonStyles['text-info']}>
            {!isNil(totalDirectAvailableSf)
              ? `${formatArea(
                  totalDirectAvailableSf,
                  UnitOfMeasurement.sf,
                )} ${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.direct`)}`
              : DASH_PLACEHOLDER}
          </span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        <InformationRow>
          <span className={commonStyles['text-info']}>
            {!isNil(totalSubletAvailableSf)
              ? `${formatArea(
                  totalSubletAvailableSf,
                  UnitOfMeasurement.sf,
                )} ${translateText(`${I18N_PROPERTY_LABEL_PATH}.sublet`)}`
              : DASH_PLACEHOLDER}
          </span>
        </InformationRow>
        <RowSeparator columnTheme={columnTheme} />
        {!isNil(availabilities) &&
        getBuildingAskingRent(availabilities, property.id!, toggleOptions!) ? (
          <AskingRentGraph
            buildingAskingRent={getBuildingAskingRent(
              availabilities,
              property.id!,
              toggleOptions!,
            )}
            marketAverageRent={getMarketAskingRent(toggleOptions!)!}
            currency={getCurrencySymbol(currencyCode)}
          />
        ) : (
          <MissingCompData
            customMissingText={customMissingText}
            type="askingRent"
            property={property}
          />
        )}
        <RowSeparator columnTheme={columnTheme} />
        {getBottomRows()}
      </>
    </ComparativeCard>
  );
};

export { AvailabilityColumn };
