import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { translateText } from 'utils/i18n';

export const YES_NO_OPTIONS = [
  {
    id: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.yes`),
    name: true,
  },
  {
    id: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.no`),
    name: false,
  },
];
