import React from 'react';
import locations from 'routes';
import { Link } from 'react-router-dom';
import { isNil, capitalize } from 'lodash';
import { ILease } from 'interfaces/ILease';
import { LeaseRentType } from 'constants/leases';
import { formatDate } from 'utils/formatters/date';
import { ITableCell } from 'interfaces/ITableCell';
import { formatArea } from 'utils/formatters/area';
import { getTranslatedRentType, isMonthlyTimeMeasurement } from 'utils/leases';
import styles from './LeasesTableContainer.module.scss';
import { isValidNumber } from 'utils/validation/number';
import { getBrokerName } from 'utils/formatters/broker';
import { upperFirstLetter } from 'utils/formatters/string';
import { getCurrencySymbol } from 'utils/formatters/currency';
import { getPropertyAddress } from 'utils/formatters/property';
import { formatNumberWithDecimals } from 'utils/formatters/number';
import { getCustomOrCalculatedNetEffectiveRent } from 'components/CreateComps/FormSection/LeaseForm/LeaseTerms/NetEffectiveRentField/utils';
import {
  UnitOfMeasurement,
  UNITS_MEASUREMENT_AREA,
} from 'constants/unitOfMeasurement';
import { TranslateFunction } from 'utils/i18n';
import {
  I18N_AVANT_PROPERTY_COMMON_LABEL_PATH,
  I18N_AVANT_PROPERTY_LABEL_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
  I18N_AVANT_PROPERTY_ROLES_PATH,
} from 'constants/i18n';

export const generateTableColumns = (
  t: TranslateFunction,
  isEULeases: boolean,
  isUKCAUSLeases: boolean,
  unitOfMeasurement?: string,
) => [
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.property`),
    id: 'primaryAddress',
    accessor: (lease: ILease) => getPropertyAddress(lease.property) || '',
    Cell: ({ cell }: ITableCell) => (
      <Link
        to={locations.showProperty(cell.row.original.property.id)}
        className={styles['cell-link']}
      >
        <div className={styles['cell-property']}>{cell.value}</div>
      </Link>
    ),
    width: 241,
    sticky: 'left',
  },
  {
    id: 'tenantName',
    header: capitalize(t(`${I18N_PLATFORM_COMMON_WORD_PATH}.tenant`)),
    accessor: 'tenantCompany.name',
    Cell: ({ cell }: ITableCell) => {
      const dest = cell?.row?.original?.tenantCompany?.id;

      if (!dest) return null;

      return (
        <Link to={locations.showCompany(dest)} className={styles['cell-link']}>
          <div className={styles['cell-tenant']}>{cell.value}</div>
        </Link>
      );
    },
    sticky: 'left',
  },
  {
    header: t(`${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.transactionType`),
    accessor: 'transactionType',
    Cell: ({ cell: { row } }: ITableCell) => {
      return row?.original?.transactionTypeFormatted ?? '';
    },
  },
  {
    header: `${t(`${I18N_PLATFORM_COMMON_WORD_PATH}.size`)} ${
      isUKCAUSLeases ? (isEULeases ? '' : 'sf') : 'sqm'
    }`,
    id: 'size',
    accessor: isUKCAUSLeases ? (isEULeases ? 'size' : 'size') : 'sizeMt',
    Cell: ({ cell: { value } }: ITableCell) => (
      <div>{formatArea(+value, '')}</div>
    ),
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.floors`),
    accessor: 'floor',
    Cell: ({ cell: { value } }: ITableCell) => {
      return value;
    },
  },
  {
    header: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.signDate`),
    accessor: 'signDate',
    Cell: ({ cell: { value } }: ITableCell) => formatDate(value, 'MMM YYYY'),
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.termMonths`),
    accessor: 'termInMonths',
    Cell: ({ cell: { value } }: ITableCell) => {
      if (!isValidNumber(value)) return null;
      return <div>{Math.floor(+value)}</div>;
    },
  },
  {
    header: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.expirationDate`),
    accessor: 'expirationDate',
    Cell: ({ cell: { value } }: ITableCell) => formatDate(value, 'MMM YYYY'),
  },
  {
    header: t(`${I18N_AVANT_PROPERTY_LABEL_PATH}.tenantTour.rent`, {
      unit: UNITS_MEASUREMENT_AREA[unitOfMeasurement || UnitOfMeasurement.sf],
    }),
    accessor: 'baseRent',
    Cell: ({ cell }: ITableCell) => {
      if (!isValidNumber(cell.value)) return null;

      const rentType = cell.row.original.rentType;
      const formattedRentType = getTranslatedRentType(rentType ?? '');

      const formattedPrice = formatNumberWithDecimals(cell.value);
      const currencySymbol = getCurrencySymbol(cell.row.original.currencyCode);

      return (
        !!formattedPrice && (
          <div>
            <span className={styles['cell-price']}>
              {`${currencySymbol}${formattedPrice} ${formattedRentType}`.trim()}
            </span>
          </div>
        )
      );
    },
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.type`),
    accessor: 'type',
    Cell: ({ cell: { value } }: ITableCell) => upperFirstLetter(value),
  },
  {
    header: `${t(`${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.taxAndOpex`)} ${
      UNITS_MEASUREMENT_AREA[unitOfMeasurement || UnitOfMeasurement.sf]
    }`,
    id: 'taxAndOpex',
    accessor: 'taxAndOpex',
    Cell: ({ cell: { value, row } }: ITableCell) => {
      if (isNil(value)) {
        return '';
      }

      const currencySymbol = getCurrencySymbol(row.original.currencyCode);
      const formattedValue = formatNumberWithDecimals(value);

      return <div>{`${currencySymbol}${formattedValue}`}</div>;
    },
  },
  {
    header: t(`${I18N_AVANT_PROPERTY_COMMON_LABEL_PATH}.escalation`),
    id: 'escalation',
    accessor: 'escalationFormatted',
    Cell: ({ cell: { value } }: ITableCell) => {
      return value;
    },
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.freeRentMonths`),
    accessor: 'freeRentMonths',
    Cell: ({ cell: { value } }: ITableCell) => <div>{value}</div>,
  },
  {
    header: `TIs ${
      UNITS_MEASUREMENT_AREA[unitOfMeasurement || UnitOfMeasurement.sf]
    }`,
    accessor: 'tenantImprovementAllowance',
    Cell: ({ cell: { value, row } }: ITableCell) => {
      if (!isValidNumber(value)) return null;

      const formattedTIs = formatNumberWithDecimals(value);
      const currencySymbol = getCurrencySymbol(row.original.currencyCode);
      return !!formattedTIs && <div>{`${currencySymbol}${formattedTIs}`}</div>;
    },
  },
  {
    header: `NER ${LeaseRentType.FS}`,
    id: 'netEffectiveRent',
    accessor: (lease: ILease) => {
      const {
        netEffectiveRentCustom,
        netEffectiveRent,
        netEffectiveRentMonthly,
        timeMeasurement,
      } = lease;
      const validNetEffectiveRent = isMonthlyTimeMeasurement(timeMeasurement)
        ? netEffectiveRentMonthly
        : netEffectiveRent;

      return formatNumberWithDecimals(
        getCustomOrCalculatedNetEffectiveRent(
          netEffectiveRentCustom,
          validNetEffectiveRent,
        ),
      );
    },
    Cell: ({ cell: { value, row } }: ITableCell) => {
      if (!value) return null;

      const currencySymbol = getCurrencySymbol(row.original.currencyCode);
      return <div>{`${currencySymbol}${value}`}</div>;
    },
  },
  {
    header: t(
      `${I18N_AVANT_PROPERTY_ROLES_PATH}.company.label.tenantRepBroker`,
    ),
    accessor: 'tenantBrokers',
    Cell: ({ cell: { value } }: ITableCell) =>
      getBrokerName(value?.[0]) || null,
  },
  {
    header: t(`${I18N_PLATFORM_COMMON_WORD_PATH}.agencyLeasingBroker`),
    accessor: 'landlordBrokers',
    Cell: ({ cell: { value } }: ITableCell) => {
      const broker = getBrokerName(value?.[0]);
      return broker ? <span className={styles.broker}>{broker}</span> : null;
    },
  },
];
