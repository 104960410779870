export const RAIL_SERVICE_OPTIONS = [
  { id: 1, name: 'Active' },
  { id: 2, name: 'Inactive' },
  { id: 3, name: 'Potential' },
  { id: 4, name: 'None' },
];

export const RAIL_PROVIDER_OPTIONS = [
  { id: 1, name: 'BNSF' },
  { id: 2, name: 'CN' },
  { id: 3, name: 'CPKC' },
  { id: 4, name: 'CSX' },
  { id: 5, name: 'NS' },
  { id: 6, name: 'UP' },
  { id: 7, name: 'Class 2' },
];
