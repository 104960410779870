import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TableView from 'components/TableView';
import { FindCompCriteriaTabs } from 'constants/FindCompTabs';
import { SortDirections } from 'constants/sortDirections';
import {
  I18N_AVANT_PROPERTY_PAGES,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { ILease } from 'interfaces/ILease';
import { SEARCH_LEASES_FIND_COMPS_QUERY } from 'graphql/leases';
import { GraphqlSearchVariables } from 'pages/FindComps';
import { translateText } from 'utils/i18n';
import { ISearchOrder } from 'interfaces/ISearch';
import { generateTableColumns } from './leaseTableHeader';

type Props = {
  graphqlSearchVariables: GraphqlSearchVariables;
  isActive: boolean;
  uncheckedIds: number[];
  onLoadData: (data?: ILease[]) => void;
  onClickEditSearch: (activeTab?: FindCompCriteriaTabs) => void;
  setIsSelectAllChecked?: (value: boolean) => void;
  setUncheckedIds: React.Dispatch<React.SetStateAction<number[]>>;
  isSelectAllChecked?: boolean;
  unitOfMeasurement?: string;
  selectedIds?: number[];
  onSelectedIds?: (ids: number[]) => void;
};

export const LEASES_TABLE_ID = 'find-comps-leases-table';

const LeasesTableContainer: React.FC<Props> = props => {
  const { t } = useTranslation();
  const {
    selectedIds,
    onSelectedIds,
    isSelectAllChecked,
    setIsSelectAllChecked,
    uncheckedIds,
    setUncheckedIds,
  } = props;

  const [isEULeases, setIsEULeases] = useState(false);
  const [isUKCAUSLeases, setIsUKCAUSLeases] = useState(false);
  const [order, setOrder] = useState<ISearchOrder>({
    field: 'signDate',
    direction: SortDirections.desc,
  });

  const getQueryVariables = (page?: number) => {
    const filter = { ...props.graphqlSearchVariables.leases?.filter };
    const hasUpwardOnly = 'upwardOnly' in filter;
    const hasMultipleBasis = 'multipleBasis' in filter;

    if (hasUpwardOnly) filter.upwardOnly = filter?.upwardOnly || null;
    if (hasMultipleBasis) filter.multipleBasis = filter?.multipleBasis || null;

    if (filter?.tenantBrokers?.length) {
      filter.tenantBrokers = filter.tenantBrokers.map(broker => broker.name);
    }
    delete filter?.ids;
    delete filter?.excludedIds;
    return {
      search: {
        order,
        filter,
        page: {
          page,
          size: 50,
        },
      },
    };
  };

  const leasesI18N = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.lease_plural`,
  );

  return (
    <TableView
      id={LEASES_TABLE_ID}
      isActive={props.isActive}
      onClickEditSearch={() =>
        props.onClickEditSearch(FindCompCriteriaTabs.lease)
      }
      noResultsMessage={translateText(
        `${I18N_AVANT_PROPERTY_PAGES}.findComps.noCompsFound`,
        { comp: leasesI18N.toLowerCase() },
      )}
      onChangeOrder={(orderField: string, orderDir: SortDirections) => {
        setOrder({ field: orderField, direction: orderDir });
        return true;
      }}
      order={order}
      columns={generateTableColumns(
        t,
        isEULeases,
        isUKCAUSLeases,
        props.unitOfMeasurement,
      )}
      hasEditButton
      hasSelectItems
      onSelectedIds={onSelectedIds}
      selectedIds={selectedIds}
      setIsSelectAllChecked={setIsSelectAllChecked}
      isSelectAllChecked={isSelectAllChecked}
      uncheckedIds={uncheckedIds}
      setUncheckedIds={setUncheckedIds}
      onCompleted={data => {
        const leases = data.leases.results as ILease[];
        if (leases) {
          setIsEULeases(leases.some(lease => lease.measurementSystem === 'EU'));
          setIsUKCAUSLeases(
            leases.some(lease => lease.measurementSystem !== 'EU'),
          );
          props.onLoadData(leases);
        }
      }}
      query={SEARCH_LEASES_FIND_COMPS_QUERY}
      getQueryVariables={getQueryVariables}
      skip={!props.graphqlSearchVariables.leases?.filter}
      typeOfData={'leases'}
    />
  );
};

export default LeasesTableContainer;
