import { I18N_PLATFORM_COMMON_WORD_PATH } from 'constants/i18n';
import { IdName } from 'interfaces/IdName';
import { SaleTenureTypeEnum } from 'interfaces/ISale';
import { translateText } from 'utils/i18n';

export const ASSET_PROFILE_OPTIONS: IdName[] = [
  {
    id: 3,
    name: 'Core',
  },
  {
    id: 1,
    name: 'Core-plus',
  },
  {
    id: 4,
    name: 'Value-Add',
  },
  {
    id: 2,
    name: 'Opportunistic',
  },
];

export const TENURE_TYPE_OPTIONS = [
  {
    id: SaleTenureTypeEnum.LONG_LEASEHOLD,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.yes`),
  },
  {
    id: SaleTenureTypeEnum.FREEHOLD,
    name: translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.no`),
  },
];
