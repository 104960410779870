import { gql } from '@apollo/client';

export const SALE_PAGINATION_DATA_FRAGMENT = gql`
  fragment salePaginationData on SaleSearchResponse {
    pageSize
    currentPage
  }
`;

export const SALE_BASIC_DATA_FRAGMENT = gql`
  fragment saleBasicData on Sale {
    id
    dealId
    buyers {
      id
      name
    }
    createdUser {
      id
      firstName
      lastName
      email
    }
    updatedUser {
      id
      firstName
      lastName
      email
    }
    buyersBrokers {
      id
      name
      company {
        id
        name
      }
    }
    createdAt
    updatedAt
    createdUser {
      id
      firstName
      lastName
      email
    }
    updatedUser {
      id
      firstName
      lastName
      email
    }
    capRate
    currencyCode
    date
    dateOnMarket
    interestPercent
    isConvertedSale
    measurementSystem
    occupancy
    partOfPortfolio
    numberOfBuildings
    partialInvVol
    price
    pricePerSF
    pricePerUnit
    pricePerSFRaw
    sf
    squareMeters
    floor
    suite
    comments
    type {
      id
      name
    }
    property {
      id
      name
      convertedFromPropertyId
      primaryAddress
      secondaryAddress
      tertiaryAddress
      quaternaryAddress
      numberOfAlternativeAddresses
      displayAddress
      buildingSize
      buildingSizeMt
      currencyCode
      measurementSystem
      coverMediaUrl
      coverMediaType
      latitude
      longitude
      latitudeDisplay
      longitudeDisplay
      status {
        id
        name
      }
      propertyCountry {
        id
        name
        code
        flagURL
        distanceMeasurement
        areaMeasurement
        currencyCode
      }
      propertyType {
        id
        name
      }
      propertySubtype {
        id
        name
      }
      propertySubtypeDetail {
        id
        name
      }
      propertyClass {
        id
        name
      }
      market {
        id
        name
      }
      submarket {
        id
        name
      }
      micromarket {
        id
        name
      }
    }
    sellers {
      id
      name
    }
    sellersBrokers {
      id
      name
      company {
        id
        name
      }
    }
    units
    tenureType
    longLeaseHoldExpiry
    longLeaseHoldGroundRent
    longLeaseHoldAdminCharge
    longLeaseHoldServiceCharge
    waultToBreakDate
    waultToExpiry
    totalRent
    vacantPossession
  }
`;

export const SALE_BASIC_DATA_NO_IMAGES_FRAGMENT = gql`
  fragment saleBasicDataNoImages on Sale {
    id
    buyers {
      id
      name
    }
    buyersBrokers {
      id
      name
      company {
        id
        name
      }
    }
    createdAt
    updatedAt
    country {
      id
      name
      code
      flagURL
      distanceMeasurement
      areaMeasurement
      currencyCode
    }
    createdUser {
      id
      firstName
      lastName
      email
    }
    updatedUser {
      id
      firstName
      lastName
      email
    }
    capRate
    currencyCode
    date
    dateOnMarket
    interestPercent
    measurementSystem
    occupancy
    partOfPortfolio
    numberOfBuildings
    partialInvVol
    price
    pricePerSF
    pricePerUnit
    pricePerSFRaw
    sf
    squareMeters
    floor
    suite
    totalRent
    type {
      id
      name
    }
    property {
      id
      name
      primaryAddress
      secondaryAddress
      tertiaryAddress
      quaternaryAddress
      numberOfAlternativeAddresses
      displayAddress
      buildingSize
      buildingSizeMt
      currencyCode
      measurementSystem
      propertyCountry {
        id
        name
        code
        flagURL
        distanceMeasurement
        areaMeasurement
        currencyCode
      }
      latitude
      longitude
      latitudeDisplay
      longitudeDisplay
      locationType {
        id
        name
      }
    }
    sellers {
      id
      name
    }
    sellersBrokers {
      id
      name
      company {
        id
        name
      }
    }
  }
`;
