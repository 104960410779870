import React from 'react';
import FormControl from 'components/FormControl';
import { IProperty } from 'interfaces/IProperty';
import { REGEX_DECIMALS } from 'constants/regex';
import styles from '../../FormSection.module.scss';
import TextInput from 'components/Inputs/TextInput';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import { translateText } from 'utils/i18n';
import UnitOfMeasurementLegend from 'components/UnitOfMeasurementLegend';
import { getUnitOfMeasurementForProperty } from 'utils/unitsOfMeasurement';
import { ModelsWithUnitsOfMeasurement } from 'constants/unitOfMeasurement';
import { getIndustrialFieldName } from 'utils/unitsOfMeasurement/index';

interface Props {
  value?: number;
  property?: IProperty | IPropertyInput;
  onChange: (field: string, value: any) => void;
  label?: string;
}

const ClearHeightMin: React.FC<Props> = ({
  value,
  property,
  onChange,
  label = 'typicalClearHeightLow',
}) => {
  const field = 'typicalClearHeightMin';
  const unitOfMeasurement = getUnitOfMeasurementForProperty(
    field,
    ModelsWithUnitsOfMeasurement.Industrial,
    property?.propertyCountry?.code || property?.measurementSystem,
  );
  return (
    <FormControl
      label={translateText(`avantPlatform.attributes.property.label.${label}`)}
      wrapperClassName={styles['form-row']}
    >
      <TextInput
        isNarrow
        textCentered
        value={value}
        placeholder={'00'}
        pattern={REGEX_DECIMALS}
        id="typical-clear-height-min"
        onChange={value =>
          onChange(getIndustrialFieldName(field, property!), value)
        }
      />
      <UnitOfMeasurementLegend
        shouldConvert={false}
        unitOfMeasurement={unitOfMeasurement}
      />
    </FormControl>
  );
};

export default ClearHeightMin;
