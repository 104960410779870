import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AgencyAssignmentsProps } from 'components/AgencyAssignments/types';
import TableView from 'components/TableView';
import {
  I18N_AVANT_PROPERTY_PAGES,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { SortDirections } from 'constants/sortDirections';
import { ISearchOrder } from 'interfaces/ISearch';
import { LEASES_TABLE_ID } from 'components/FindComps/LeasesTableContainer';
import { SEARCH_LEASES_BASIC_QUERY } from 'graphql/leases/queries';
import { leaseTableHeader } from './leasesTableHeader';
import styles from './LeasesContainer.module.scss';

interface ListProps extends AgencyAssignmentsProps {
  order: ISearchOrder;
  setOrder: (value: ISearchOrder) => void;
  propertyIds: number[];
}

const ListContainer: React.FC<ListProps> = ({
  unitOfMeasurement,
  order,
  setOrder,
  propertyIds,
}) => {
  const { t } = useTranslation();
  const [uncheckedIds, setUncheckedIds] = useState<number[]>([]);

  const getQueryVariables = () => {
    return {
      search: {
        filter: {
          propertiesIds: propertyIds,
          onlyNotExpired: true,
        },
        order,
      },
    };
  };

  return (
    <div className={styles['table-const']}>
      <TableView
        id={LEASES_TABLE_ID}
        noResultsMessage={t(
          `${I18N_AVANT_PROPERTY_PAGES}.findComps.noCompsFound`,
          {
            comp: t(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.lease_plural`,
            ).toLowerCase(),
          },
        )}
        columns={leaseTableHeader(t, unitOfMeasurement)}
        scrollClassName={styles['scroll-position']}
        isActive
        hasEditButton
        displayEditCriteriaBtn={false}
        onChangeOrder={(orderField: string, orderDir: SortDirections) => {
          setOrder({
            field: orderField,
            direction: orderDir,
          });
          return true;
        }}
        order={order}
        uncheckedIds={uncheckedIds}
        setUncheckedIds={setUncheckedIds}
        query={SEARCH_LEASES_BASIC_QUERY}
        skip={!propertyIds.length}
        getQueryVariables={getQueryVariables}
        typeOfData={'leases'}
      />
    </div>
  );
};

export default ListContainer;
