import React from 'react';
import { useTranslation } from 'react-i18next';

import PropertiesSearchInput from 'components/PropertiesSearchInput';
import { SearchInputSize } from 'components/SearchInput/enums';
import FormControl from 'components/FormControl';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import TextInput from 'components/Inputs/TextInput';
import RadioInput from 'components/Inputs/RadioInput';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import Occupancy from 'components/CreateComps/FormSection/FormFields/Occupancy';
import { UNITS_MEASUREMENT_AREA } from 'constants/unitOfMeasurement';
import { YES_NO_OPTIONS } from 'constants/commonOptions';
import { REGEX_DECIMALS, REGEX_INTEGERS } from 'constants/regex';
import {
  I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { IProperty } from 'interfaces/IProperty';
import { IPropertyCompany } from 'interfaces/IPropertyCompany';
import { IdName } from 'interfaces/IdName';
import { SEARCH_COMPANIES_PROPERTIES_QUERY } from 'graphql/common';
import {
  currencyInputFormatter,
  getCurrencySymbol,
  parseCurrencyToNumber,
} from 'utils/formatters/currency';
import { fieldHasValue } from 'utils/objects';
import { translateText } from 'utils/i18n';

import { SaleFormSectionProps } from '..';
import styles from '../../FormSection.module.scss';
import {
  calculatePartialInvestmentVolume,
  calculateInterestPercent,
} from '../../formUtils';
import {
  CAP_RATE_SUGGESTIONS,
  PART_PORTFOLIO_SUGGESTIONS,
} from '../suggestions';

interface Props extends SaleFormSectionProps {
  saleCurrencySymbol: string;
  unitOfMeasurement: string;
  isMultiFamilyProperty: boolean;
  isUkSales: boolean;
  handleSelectProperty: (property: IProperty) => void;
  updatePricePsf: (
    salePrice?: number,
    saleType?: IdName,
    condoSize?: number,
  ) => void;
}

const BasicInformation: React.FC<Props> = ({
  isMultiFamilyProperty,
  isUkSales,
  saleCurrencySymbol,
  saleInput,
  unitOfMeasurement,
  updatePricePsf,
  updateSaleProperty,
  handleSelectProperty,
}) => {
  const { t } = useTranslation();

  const onChangePartOfPortfolio = (item?: IdName) => {
    if (!item?.name) {
      updateSaleProperty('numberOfBuildings', null);
    }
    updateSaleProperty('partOfPortfolio', item?.name);
  };

  const getSalePriceValue = (priceValueFormatted: string): string | number => {
    return priceValueFormatted.endsWith('.')
      ? priceValueFormatted
      : parseCurrencyToNumber(priceValueFormatted);
  };

  const updatePartialInvestmentVolume = (
    interestPercent: number,
    price: number,
  ) => {
    updateSaleProperty(
      'partialInvVol',
      calculatePartialInvestmentVolume(interestPercent, price),
    );
  };

  const handleInterestPercent = (value: string) => {
    const newValue = value.length ? value : null;
    const { price } = saleInput;

    updateSaleProperty('interestPercent', newValue);
    updatePartialInvestmentVolume(
      Number(newValue),
      price ? parseCurrencyToNumber(price.toString()) : 0,
    );
  };

  const handleTotalPrice = (value: string) => {
    if (!value.length) {
      updateSaleProperty('price', null);
      updateSaleProperty('pricePerSF', null);
      updateSaleProperty('pricePerUnit', null);
      return;
    }

    updateSaleProperty('price', getSalePriceValue(value));
    const parsedValue = getSalePriceValue(value);
    updateSaleProperty('price', parsedValue);

    const valueNumber = parseCurrencyToNumber(value);
    updatePricePsf(valueNumber, saleInput.type);

    if (fieldHasValue(saleInput.interestPercent)) {
      updatePartialInvestmentVolume(saleInput.interestPercent!, valueNumber);
    }
  };

  const handlePartialInvestmentVolume = (value: string) => {
    if (!value.length) {
      updateSaleProperty('partialInvVol', null);
      return;
    }

    const { price } = saleInput;
    const valueAsNumber = parseCurrencyToNumber(value);

    updateSaleProperty('partialInvVol', getSalePriceValue(value));
    updateSaleProperty(
      'interestPercent',
      calculateInterestPercent(valueAsNumber, price || 0),
    );
  };

  return (
    <>
      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.selectProperty`)}
        required
        wrapperClassName={styles['form-row']}
      >
        <PropertiesSearchInput
          inputId={'sale-property-input-id'}
          searchInputProps={{ size: SearchInputSize.Small }}
          onClickSuggestion={(property: IProperty) => {
            property.id = property.propertyId ?? property.id;
            property.primaryAddress = property.primaryAddress ?? property.name;
            handleSelectProperty(property);
          }}
          property={{
            id: saleInput.property?.id,
            primaryAddress: saleInput.property?.primaryAddress,
            name: saleInput.property?.name,
          }}
          placeholder={t(
            `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterAddress`,
          )}
          graphqlParams={{
            query: SEARCH_COMPANIES_PROPERTIES_QUERY,
            field: 'name',
            resultDataName: 'propertiesCompanies',
            onlyProperties: true,
          }}
          withMarketSwitch
          onClearSelectedSuggestion={() => {
            updateSaleProperty('currencyCode', getCurrencySymbol());
            updateSaleProperty('property', null);
          }}
          parseResults={(data: IPropertyCompany[]): IPropertyCompany[] => {
            return data.map(propertyCompany => ({
              ...propertyCompany,
              name: propertyCompany.displayAddress || propertyCompany.name,
            }));
          }}
        />
      </FormControl>

      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.partOfPortfolio`)}
        wrapperClassName={styles['form-row']}
      >
        <div className={styles['wrapper-radio-container']}>
          <ChoiceInput
            labelFieldName={'id'}
            selectedItem={{
              name: saleInput.partOfPortfolio,
            }}
            data={YES_NO_OPTIONS}
            containerWrapperClassName={styles['radio-input-container']}
            itemWrapperClassName={styles['button-input-item']}
            onChange={onChangePartOfPortfolio}
          />
          <TextInput
            pattern={REGEX_INTEGERS}
            isDisabled={!saleInput.partOfPortfolio}
            value={saleInput.numberOfBuildings}
            placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
            onChange={value => updateSaleProperty('numberOfBuildings', value)}
          />
          <RadioInput
            isAllDisabled={!saleInput.partOfPortfolio}
            selectedItem={saleInput.numberOfBuildings}
            data={PART_PORTFOLIO_SUGGESTIONS}
            onChange={item => {
              updateSaleProperty('numberOfBuildings', item.value);
            }}
          />
        </div>
      </FormControl>

      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.priceUnitOfMeasurement`, {
          unitOfMeasurement: UNITS_MEASUREMENT_AREA[unitOfMeasurement],
        })}
        wrapperClassName={styles['form-row']}
      >
        <CurrencyInput
          currencySymbol={saleCurrencySymbol}
          value={saleInput.pricePerSF}
          id={'price-psf'}
          useDefaultCurrencyFormatter
          isDisabled
          formatter={currencyInputFormatter}
        />
      </FormControl>
      {isMultiFamilyProperty && (
        <FormControl
          label={translateText(
            `${I18N_PLATFORM_COMMON_WORD_PATH}.pricePerUnit`,
          )}
          wrapperClassName={styles['form-row']}
        >
          <CurrencyInput
            currencySymbol={saleCurrencySymbol}
            value={saleInput.pricePerUnit}
            id={'price-per-unit'}
            useDefaultCurrencyFormatter
            isDisabled
            formatter={currencyInputFormatter}
          />
        </FormControl>
      )}
      <FormControl
        label={t('avantProperties.labels.common.totalPrice')}
        wrapperClassName={styles['form-row']}
      >
        <CurrencyInput
          currencySymbol={saleCurrencySymbol}
          value={saleInput.price}
          placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          id="price"
          onChange={value => {
            handleTotalPrice(value);
          }}
          formatter={currencyInputFormatter}
        />
      </FormControl>

      <Occupancy
        value={saleInput.occupancy}
        vacantPossession={saleInput.vacantPossession}
        onChange={updateSaleProperty}
        isUk={isUkSales}
      />

      <FormControl
        label={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.capRate`)}
        wrapperClassName={styles['form-row']}
      >
        <div className={styles['wrapper-radio-container']}>
          <TextInput
            id={'sale-cap-rate'}
            pattern={REGEX_DECIMALS}
            value={saleInput.capRate}
            placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
            onChange={value => updateSaleProperty('capRate', value)}
          />
          <RadioInput
            selectedItem={saleInput.capRate}
            data={CAP_RATE_SUGGESTIONS}
            onChange={item => {
              updateSaleProperty('capRate', item.value);
            }}
          />
        </div>
      </FormControl>

      <FormControl
        label={t('avantProperties.labels.sales.partialInterest')}
        wrapperClassName={styles['form-row']}
        required
      >
        <TextInput
          iconPosition="right"
          value={saleInput.interestPercent}
          inputClassName={styles['percentage-input']}
          placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          id={'interest-percent'}
          onChange={handleInterestPercent}
          pattern={REGEX_DECIMALS}
        />
      </FormControl>

      <FormControl
        label={t('avantProperties.labels.common.partialInvestmentVolume')}
        wrapperClassName={styles['form-row']}
      >
        <CurrencyInput
          value={saleInput.partialInvVol}
          currencySymbol={saleCurrencySymbol}
          placeholder={t(`${I18N_PLATFORM_COMMON_WORD_PATH}.enter`)}
          id={'partial-inv-vol'}
          onChange={handlePartialInvestmentVolume}
          formatter={currencyInputFormatter}
        />
      </FormControl>
    </>
  );
};

export default BasicInformation;
