import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IProperty } from 'interfaces/IProperty';
import locations from 'routes';
import { CompType } from 'constants/compType';
import { I18N_AVANT_PROPERTY_TEXT_PATH } from 'constants/i18n';
import EditHistoricalPerformance from 'components/HistoricalPerformance/HistoricalPerformanceModal/EditHistoricalPerformance';

import styles from './MissingCompData.module.scss';

interface Props {
  type: 'askingRent' | 'availability' | 'sale' | 'lease' | 'tenantInformation';
  property: IProperty | undefined;
  customMissingText?: string;
}

const MissingCompData: React.FC<Props> = ({
  property,
  type,
  customMissingText,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isPerformanceModalVisible, setIsPerformanceModalVisible] = useState(
    false,
  );

  const tabTypeMap: Record<string, CompType> = {
    lease: CompType.lease,
    sale: CompType.sale,
    tenantInformation: CompType.lease,
    askingRent: CompType.pricingQuote,
  };

  const tabType = tabTypeMap[type] || CompType.lease;

  const splitMissingDataText = (
    missingDataText: string,
    isLinkText: boolean,
  ) => {
    if (isLinkText) {
      return missingDataText.slice(
        missingDataText.indexOf('click'),
        missingDataText.length,
      );
    }
    return missingDataText.slice(0, missingDataText.indexOf('click'));
  };

  const getMissingDataText = (isLinkText: boolean) => {
    if (customMissingText) return isLinkText ? '' : customMissingText;

    const missingDataMapper: Record<string, string> = {
      lease: 'missingLeaseData',
      sale: 'missingSaleData',
      tenantInformation: 'missingTenantInformationData',
      availability: 'missingAssetPerformanceData',
      askingRent: 'missingAskingRentData',
    };

    const missingDataKey = missingDataMapper[type];
    if (missingDataKey) {
      return splitMissingDataText(
        t(`${I18N_AVANT_PROPERTY_TEXT_PATH}.notFound.${missingDataKey}`),
        isLinkText,
      );
    }
    return '';
  };

  const layoutClassNames: Record<string, string> = {
    lease: styles['lease-layout'],
    sale: styles['sale-layout'],
    tenantInformation: styles['tenant-information-layout'],
    availability: styles['availability-layout'],
    askingRent: styles['asking-rent-layout'],
  };
  const layoutClassName = layoutClassNames[type] || '';

  return (
    <div className={layoutClassName}>
      <span className={styles.text}>
        {getMissingDataText(false)}
        <span
          className={styles['link-text']}
          onClick={() => {
            if (type === 'availability') {
              setIsPerformanceModalVisible(true);
              return;
            }
            history.push(locations.newComps(), {
              sourceProperty: property,
              tabType: tabType,
            });
          }}
        >
          {getMissingDataText(true)}
        </span>
      </span>
      {isPerformanceModalVisible && (
        <EditHistoricalPerformance
          propertyId={property?.id}
          onClose={() => setIsPerformanceModalVisible(false)}
        />
      )}
    </div>
  );
};

export default MissingCompData;
