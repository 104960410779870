import { useContext, useEffect, useState } from 'react';
import { PureQueryOptions, useMutation, useQuery } from '@apollo/client';
import { flatten, noop, uniqBy } from 'lodash';

import { authContext } from 'contexts/AuthContext';
import { SortDirections } from 'constants/sortDirections';
import { PropertySetTypes } from 'constants/propertySetTypes';
import { IAvailability } from 'interfaces/IAvailability';
import { IPropertySet } from 'interfaces/inputs/IPropertySet';
import { IPropertySetFilterInput } from 'interfaces/IPropertySetFilterInput';
import { convertIPropertyIntoIPropertyLookupInput } from 'utils/properties';
import { removeTypenameKey } from 'utils/graphql/typename';
import { GET_AVAILABILITIES_QUERY } from 'graphql/availabilities';
import {
  CREATE_PROPERTY_SET_MUTATION,
  DELETE_PROPERTY_SET_MUTATION,
  GET_PROPERTY_SETS_QUERY,
  UPDATE_PROPERTY_SET_MUTATION,
} from 'graphql/propertySet';
import { CompSetOrderContext } from '../contexts/CompSetOrderContext';

type Options = {
  isEditing?: boolean;
  onDelete?: (propertySet: IPropertySet) => void;
  onSubmit?: (propertySet: IPropertySet) => void;
  onChange?: (propertySet: IPropertySet) => void;
  type?: PropertySetTypes;
  refetchQueries?: PureQueryOptions[];
};

type IPropertySetCreateResult = {
  createPropertySet: IPropertySet;
  updatePropertySet: never;
};

type IPropertySetUpdateResult = {
  updatePropertySet: IPropertySet;
  createPropertySet: never;
};

type IPropertySetResult = IPropertySetCreateResult | IPropertySetUpdateResult;

/**
 * Returns the availabilities of the comp set properties.
 */
const getAvailabilities = (propertySet?: IPropertySet | null) =>
  flatten(
    propertySet?.properties
      ?.filter(property => property.availabilities?.length)
      .map(property => {
        return property.availabilities?.map(availability => ({
          ...availability,
          id: undefined,
          property: convertIPropertyIntoIPropertyLookupInput(property),
        }));
      }),
  );

const usePropertySet = (
  sourcePropertySet?: IPropertySet | null,
  {
    type = PropertySetTypes.AGENCY,
    isEditing = false,
    onSubmit = noop,
    onDelete = noop,
    onChange = noop,
    refetchQueries,
  }: Options | undefined = {},
) => {
  const { user } = useContext(authContext);
  const { compSetOrder, setCompSetOrder } = useContext(CompSetOrderContext);

  const [propertySet, setPropertySet] = useState<
    IPropertySet | null | undefined
  >(sourcePropertySet);

  const generatePropertiesPayload = () => {
    const targetComps = compSetOrder?.targetComps?.map(t => ({
      ...t,
      featured: true,
    }));
    const compSet = compSetOrder?.compSet?.map(t => ({
      ...t,
      featured: false,
    }));
    const hasCompSet = propertySet?.properties?.some(p => !p.featured);
    const contextAllCompSet = hasCompSet
      ? uniqBy([...targetComps, ...compSet], 'propertyId')
      : uniqBy(targetComps, 'propertyId');

    return !contextAllCompSet?.length
      ? propertySet?.properties?.map((p, index) => ({
          propertyId: p.id!,
          order: index,
          ...(type === PropertySetTypes.AGENCY && {
            featured: p.featured,
          }),
        }))
      : contextAllCompSet.map((comp, i) => {
          return {
            propertyId: comp.propertyId,
            order: i,
            ...(type === PropertySetTypes.AGENCY && {
              featured: comp?.featured,
            }),
          };
        });
  };

  const getRefetchQueries = (customFilter: IPropertySetFilterInput = {}) => [
    {
      query: GET_PROPERTY_SETS_QUERY,
      variables: {
        search: {
          filter: {
            type,
            ...customFilter,
          },
          order: {
            field: 'createdAt',
            direction: SortDirections.asc,
          },
        },
      },
    },
    ...(refetchQueries || []),
  ];

  const [submitPropertySet, { loading: isSubmitting }] = useMutation<
    IPropertySetResult
  >(isEditing ? UPDATE_PROPERTY_SET_MUTATION : CREATE_PROPERTY_SET_MUTATION, {
    variables: {
      propertySet: removeTypenameKey({
        type,
        id: propertySet?.id,
        ...(type !== PropertySetTypes.AGENCY && {
          propertyId: propertySet?.targetProperty?.id,
        }),
        propertyId:
          type !== PropertySetTypes.AGENCY
            ? propertySet?.targetProperty?.id
            : generatePropertiesPayload()?.[0]?.propertyId,

        tenantCompanyId: propertySet?.targetTenant?.id,
        userId: propertySet?.userId || user?.id,
        fileId: propertySet?.fileId,
        name: propertySet?.name,

        properties: generatePropertiesPayload(),
        availabilities: getAvailabilities(propertySet),
      }),
    },
    refetchQueries: getRefetchQueries(),
    onCompleted: response => {
      const responseField = response.createPropertySet
        ? 'createPropertySet'
        : 'updatePropertySet';
      // This is a temporary solution to a BE issue in which the `propertySet` query
      // returns outdated data for the target property and null for the list of
      // properties in the property set.
      const newPropertySet = {
        ...response[responseField],
        ...propertySet,
      };

      onSubmit(newPropertySet);
    },
  });

  const [deletePropertySet, { loading: isDeleting }] = useMutation(
    DELETE_PROPERTY_SET_MUTATION,
    {
      variables: {
        propertySetId: propertySet?.id,
      },
      refetchQueries: getRefetchQueries(),
      onCompleted: () => {
        if (propertySet) {
          onDelete(propertySet);
        }
      },
    },
  );

  const { loading: isLoadingAvailabilities, data: availabilityData } = useQuery(
    GET_AVAILABILITIES_QUERY,
    {
      variables: {
        search: {
          filter: {
            propertySetId: propertySet?.id,
          },
        },
      },
      skip: !propertySet?.id,
    },
  );

  useEffect(() => {
    if (!isLoadingAvailabilities) {
      const availabilities = availabilityData?.availabilities?.results || [];

      if (availabilities.length && propertySet?.properties?.length) {
        // We add availabilities to each property in order to match the `PropertySetInput` interface.
        setPropertySet({
          ...propertySet,
          properties: propertySet.properties.map(property => ({
            ...property,
            availabilities: availabilities.filter(
              (availability: IAvailability) =>
                availability.property?.id === property.id,
            ),
          })),
        });
      }
    }
  }, [isLoadingAvailabilities, propertySet?.properties?.length]);

  const updatePropertySet = (key: keyof IPropertySet, value: any) => {
    const newPropertySet = {
      ...propertySet,
      [key]: value,
    };

    setPropertySet(newPropertySet);
    onChange(newPropertySet);
  };

  useEffect(() => {
    // reset context every time when open compset
    setCompSetOrder({
      compSet: [],
      targetComps: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoadingAvailabilities,
    propertySet,
    updatePropertySet,
    getRefetchQueries,
    submitPropertySet,
    deletePropertySet,
    isDeleting,
    isSubmitting,
    setPropertySet,
  };
};

export default usePropertySet;
