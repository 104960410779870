import dayjs from 'dayjs';
import IIndustrial from 'interfaces/IIndustrial';
import { getFieldValueOrNull } from 'utils/objects';
import { PropertyTypeNames, PropertyTypes } from 'constants/propertyTypes';
import { removeTypenameKey } from 'utils/graphql/typename';
import { ICompanyInput } from 'interfaces/inputs/ICompanyInput';
import { IPropertyInput } from 'interfaces/inputs/IPropertyInput';
import {
  isDataCenterBuilding,
  isIndustrialBuilding,
  isLandAndDevelopmentBuilding,
  isLifeScienceBuilding,
  isMultifamilyBuilding,
  isOfficeBuilding,
} from 'utils/properties';
import IDataCenterInput from 'interfaces/inputs/IDataCenterInput';
import IMultifamilyInput from 'interfaces/inputs/IMultifamilyInput';
import { ICompany } from 'interfaces/ICompany';
import { ILifeScience } from 'interfaces/inputs/ILifeScience';
import { PropertySubtypeNames } from 'constants/propertySubtypes';
import { LocationTypeNames } from 'constants/locationTypes';
import { TenancyTypeNames } from 'constants/tenancyTypes';
import {
  UnitOfMeasurement,
  UNITS_OF_MEASUREMENT_CODE_MAPPING,
} from 'constants/unitOfMeasurement';
import { removeCommaFromNumber } from 'utils/formatters/number';
import { DataCenterBuildType } from 'constants/dataCenter';

interface NameInput {
  name: string;
}

interface IPropertyPayload {
  id?: number;
  amps?: number;
  primaryAddress: string;
  secondaryAddress: string;
  tertiaryAddress: string;
  quaternaryAddress: string;
  displayAddress: string;
  name?: string;
  city: string;
  craneServed?: boolean;
  craneCapacity?: number;
  convertedFromPropertyId?: number | null;
  hookHeight?: number;
  hookHeightMt?: number;
  state: string;
  postalCode?: string;
  market: NameInput;
  submarket?: NameInput | null;
  micromarket?: NameInput | null;
  propertyType: NameInput;
  industrial?: IIndustrial;
  parkingSpots?: number | null;
  propertySubtype?: NameInput;
  propertySubtypeDetail?: NameInput | null;
  tenancyType?: NameInput | null;
  status: NameInput;
  locationType: NameInput | null;
  propertyClass?: NameInput;
  buildingSize?: number;
  businessPark?: NameInput;
  buildingSizeMt?: number;
  stories?: number;
  floorPlateSize?: number;
  floorPlateSizeMt?: number;
  uarn?: number[];
  currentYearTaxAssessment?: number;
  siteNumber?: number;
  siteNumberProviderCompany?: NameInput | null;
  breeam?: string;
  energyPerformanceCertificate?: string;
  fitwell?: string;
  well?: string;
  planningStatus?: string;
  numberOfElevators?: number;
  office?: {
    columnSpacingMax?: number;
    columnSpacingMaxMt?: number;
    columnSpacingMin?: number;
    columnSpacingMinMt?: number;
    typicalCeilingHeightMt?: number;
    typicalCeilingHeightFeet?: number;
    typicalCeilingHeightInches?: number;
    storiesAboveGround?: number;
    storiesBelowGround?: number;
  };
  lifeScience?: ILifeScience;
  constructionType?: NameInput | null;
  dataCenter?: IDataCenterInput;
  multifamily?: IMultifamilyInput;
  healthcare?: {
    numBeds?: number;
    providerCompanies?: NameInput[];
  };
  siteSizeMt?: number;
  zoning?: string;
  siteSizeAcres?: number;
  siteSizeSf?: number;
  parkingRatio?: number;
  renovatedYears?: { year: number }[];
  owners?: ICompany[];
  longLeaseHolders?: NameInput[];
  leasingCompany?: NameInput | null;
  architectCompany?: NameInput | null;
  developmentCompanies?: ICompanyInput[];
  managementCompany?: NameInput | null;
  operatorCompany?: NameInput | null;
  amenities?: NameInput[];
  ownerOccupied?: NameInput | null;
  expense?: {
    tax?: number;
    operatingExpenses?: number;
    taxAndOpex?: number;
    year?: number;
    taxAndOpexChangedManually?: boolean;
    timeMeasurement?: string;
  };
  comments?: { text: string }[];
  description?: string;
  latitude?: number;
  longitude?: number;
  latitudeDisplay?: number;
  longitudeDisplay?: number;
  siteSize?: number;
  siteSizeHectares?: number;
  currencyCode?: string;
  measurementSystem?: string;
  thirdPartyId?: string;
  includeInStats?: boolean;
  buildDate?: string;
  builtYear?: number | null;
  brokeGroundDate?: string;
  demolishedConverted?: string;
  officeSize?: number;
  officeSizeMt?: number;
  warehouseSize?: number;
  warehouseSizeMt?: number;
  preLeasedSf?: number | null;
  preLeasedSm?: number | null;
  preLeasedPct?: number | null;
  preLeasedCustomSf?: number | null;
  preLeasedCustomSm?: number | null;
  preLeasedCustomPct?: number | null;
}

const removeUndefinedAndTypenames = (obj: any) => {
  obj = removeTypenameKey(obj);
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeUndefinedAndTypenames(obj[key]);
    } else if (obj[key] === undefined) {
      delete obj[key];
    }
  });

  return obj;
};

const getIndustrialPayload = (property: IPropertyInput) => {
  const { industrial, measurementSystem } = property;
  const mappedMeasurementSystem =
    UNITS_OF_MEASUREMENT_CODE_MAPPING[measurementSystem!];

  const MEASUREMENTS_UNITS = [
    'typicalClearHeightMin',
    'typicalClearHeightMinMt',
    'typicalClearHeightMax',
    'typicalClearHeightMaxMt',
  ];

  const canBeNulled = (field: string) => {
    return !MEASUREMENTS_UNITS.filter(t => t === field);
  };

  const industrialValues: { [key: string]: any } = {
    amps: industrial?.amps ? +industrial?.amps : null,
    craneServed: industrial?.craneServed,
    craneCapacity: industrial?.craneCapacity
      ? +industrial?.craneCapacity
      : null,
    hookHeight: industrial?.hookHeight ? +industrial?.hookHeight : null,
    hookHeightMt: industrial?.hookHeightMt ? +industrial?.hookHeightMt : null,
    heavyPower: industrial?.heavyPower,
    sprinklers: industrial?.sprinklers,
    driveInDoors: industrial?.driveInDoors,
    loadingDocks: industrial?.loadingDocks,
    buildingDepth: industrial?.buildingDepth,
    outsideStorage: industrial?.outsideStorage,
    businessParkType: industrial?.businessParkType,
    dockConfiguration: industrial?.dockConfiguration,
    dockConfigurationType: industrial?.dockConfigurationType,
    trailerParkingSpaces: industrial?.trailerParkingSpaces,
    typicalClearHeightMin: industrial?.typicalClearHeightMin,
    typicalClearHeightMinMt: industrial?.typicalClearHeightMinMt,
    typicalClearHeightMax: industrial?.typicalClearHeightMax,
    typicalClearHeightMaxMt: industrial?.typicalClearHeightMaxMt,
    columnSpacingMin: industrial?.columnSpacingMin,
    columnSpacingMinMt: industrial?.columnSpacingMinMt,
    columnSpacingMax: industrial?.columnSpacingMax,
    columnSpacingMaxMt: industrial?.columnSpacingMaxMt,
    transportationConnectivity: industrial?.transportationConnectivity,
    railServed: industrial?.railServed,
    railProvider: industrial?.railProvider,
  };

  Object.keys(industrialValues).forEach(key => {
    if (canBeNulled(key)) {
      industrialValues[key] = getFieldValueOrNull(industrialValues[key]);
    }
  });
  if (mappedMeasurementSystem == 'US') {
    delete industrialValues.columnSpacingMaxMt;
    delete industrialValues.columnSpacingMinMt;
  } else {
    delete industrialValues.columnSpacingMax;
    delete industrialValues.columnSpacingMin;
  }

  return industrialValues;
};

const getOfficePayload = (property: IPropertyInput) => ({
  columnSpacingMax: property.office?.columnSpacingMax,
  columnSpacingMaxMt: property.office?.columnSpacingMaxMt,
  columnSpacingMin: property.office?.columnSpacingMin,
  columnSpacingMinMt: property.office?.columnSpacingMinMt,
  typicalCeilingHeightFeet: property.office?.typicalCeilingHeightFeet,
  typicalCeilingHeightInches: property.office?.typicalCeilingHeightInches,
  typicalCeilingHeightMt: property.office?.typicalCeilingHeightMt,
  storiesAboveGround: property.office?.storiesAboveGround,
  storiesBelowGround: property.office?.storiesBelowGround,
});

const getLifeSciencePayload = (property: IPropertyInput) => ({
  conversion: property.lifeScience?.conversion,
  dockConfiguration: property.lifeScience?.dockConfiguration,
  dockConfigurationType: property.lifeScience?.dockConfigurationType,
  driveInDoors: property.lifeScience?.driveInDoors,
  loadingDocks: property.lifeScience?.loadingDocks,
  slabCeilingHeightFeet: property.lifeScience?.slabCeilingHeightFeet,
  slabCeilingHeightInches: property.lifeScience?.slabCeilingHeightInches,
  typicalClearHeightMax: property.lifeScience?.typicalClearHeightMax,
  typicalClearHeightMin: property.lifeScience?.typicalClearHeightMin,
  columnSpacingMax: property.lifeScience?.columnSpacingMax,
  columnSpacingMaxMt: property.lifeScience?.columnSpacingMaxMt,
  columnSpacingMin: property.lifeScience?.columnSpacingMin,
  columnSpacingMinMt: property.lifeScience?.columnSpacingMinMt,
  typicalCeilingHeightFeet: property.lifeScience?.typicalCeilingHeightFeet,
  typicalCeilingHeightInches: property.lifeScience?.typicalCeilingHeightInches,
  typicalCeilingHeightMt: property.lifeScience?.typicalCeilingHeightMt,
  storiesAboveGround: property.lifeScience?.storiesAboveGround,
  storiesBelowGround: property.lifeScience?.storiesBelowGround,
});

const getDataCenterPayload = (property: IPropertyInput) => ({
  typicalCeilingHeightMt: property.dataCenter?.typicalCeilingHeightMt,
  typicalCeilingHeightFeet: property.dataCenter?.typicalCeilingHeightFeet,
  typicalCeilingHeightInches: property.dataCenter?.typicalCeilingHeightInches,
  underConstPower: property.dataCenter?.underConstPower,
  underConstSf: property.dataCenter?.underConstSf,
  underConstMt: property.dataCenter?.underConstMt,
  plannedPower: property.dataCenter?.plannedPower,
  plannedSf: property.dataCenter?.plannedSf,
  plannedMt: property.dataCenter?.plannedMt,
  commissionedPower: property.dataCenter?.commissionedPower,
  commissionedSf: property.dataCenter?.commissionedSf,
  commissionedMt: property.dataCenter?.commissionedMt,
  costPerKwhMin: property.dataCenter?.costPerKwhMin,
  costPerKwhMax: property.dataCenter?.costPerKwhMax,
  feedType: property.dataCenter?.feedType,
  hasOnsiteSubstation: property.dataCenter?.hasOnsiteSubstation,
  powerProviderCompany: property.dataCenter?.powerProviderCompany
    ? { name: property.dataCenter?.powerProviderCompany?.name }
    : null,
  numSubstationsServingFacility:
    property.dataCenter?.numSubstationsServingFacility,
  raisedFloorHeight: property.dataCenter?.raisedFloorHeight,
  raisedFloorHeightMt: property.dataCenter?.raisedFloorHeightMt,
  ups: property.dataCenter?.ups,
  upsDescription: property.dataCenter?.upsDescription,
  generator: property.dataCenter?.generator,
  generatorDescription: property.dataCenter?.generatorDescription,
  pdu: property.dataCenter?.pdu,
  pduDescription: property.dataCenter?.pduDescription,
  crah: property.dataCenter?.crah,
  crahDescription: property.dataCenter?.crahDescription,
  cooling: property.dataCenter?.cooling,
  fiberLinesCompanies:
    property.dataCenter?.fiberLinesCompanies &&
    property.dataCenter?.fiberLinesCompanies.map(({ name }) => ({ name })),
  cloudServicesCompanies:
    property.dataCenter?.cloudServicesCompanies &&
    property.dataCenter?.cloudServicesCompanies.map(({ name }) => ({ name })),
  retrofitYear: property.dataCenter?.retrofitYear,
  powerUseEfficiency: property.dataCenter?.powerUseEfficiency,
  dataCenterBuildOutType: property.dataCenter?.dataCenterBuildOutType
    ? { name: property.dataCenter?.dataCenterBuildOutType.name }
    : null,
});

const getMultifamilyPayload = (property: IPropertyInput) => ({
  totalUnits: property?.multifamily?.totalUnits,
  avgUnitSizeMt: property?.multifamily?.avgUnitSizeMt,
  avgUnitSizeSf: property?.multifamily?.avgUnitSizeSf,
  unitsZeroBed: property?.multifamily?.unitsZeroBed,
  unitsOneBed: property?.multifamily?.unitsOneBed,
  unitsTwoBed: property?.multifamily?.unitsTwoBed,
  unitsThreeBed: property?.multifamily?.unitsThreeBed,
});

export const buildPropertyPayload = (
  property: IPropertyInput,
): IPropertyPayload => {
  const isSqmMeasurement =
    property.propertyCountry?.areaMeasurement === UnitOfMeasurement.sm;

  let payload: IPropertyPayload = {
    id: property.id,
    primaryAddress: property.primaryAddress!,
    secondaryAddress: property.secondaryAddress || '',
    tertiaryAddress: property.tertiaryAddress || '',
    quaternaryAddress: property.quaternaryAddress || '',
    displayAddress: property.displayAddress!,
    name: property.name,
    city: property.city!,
    state: property.state!,
    postalCode: property.postalCode,
    market: { name: property.market?.name },
    submarket: property.submarket ? { name: property.submarket.name } : null,
    micromarket: property.micromarket
      ? { name: property.micromarket.name }
      : null,
    propertyType: {
      name: property.propertyType?.id
        ? PropertyTypeNames[property.propertyType.id]
        : PropertyTypes.office,
    },
    propertySubtype: property.propertySubtype && {
      name: PropertySubtypeNames[property.propertySubtype.id],
    },
    propertySubtypeDetail: property.propertySubtypeDetail?.name
      ? {
          name: property.propertySubtypeDetail.name,
        }
      : null,
    tenancyType: property.tenancyType?.name
      ? { name: TenancyTypeNames[property.tenancyType?.id] }
      : null,
    locationType: property.locationType?.name
      ? { name: LocationTypeNames[property.locationType?.id] }
      : null,
    status: { name: property.status?.name },
    propertyClass: property.propertyClass && {
      name: property.propertyClass.name,
    },
    businessPark: property.businessPark && {
      name: property.businessPark?.name,
    },
    constructionType: property.constructionType
      ? {
          name: property.constructionType?.name,
        }
      : null,
    buildingSize: property.buildingSize,
    buildingSizeMt: property.buildingSizeMt,
    officeSize: property.officeSize,
    officeSizeMt: property.officeSizeMt,
    warehouseSize: property.warehouseSize,
    warehouseSizeMt: property.warehouseSizeMt,
    stories: property.stories,
    floorPlateSize: property.floorPlateSize,
    floorPlateSizeMt: property.floorPlateSizeMt,
    parkingRatio: property.parkingRatio,
    renovatedYears:
      property.renovatedYears
        ?.filter(ry => !!ry?.year)
        ?.map(ry => ({ year: ry.year })) || [],
    owners: property.owners && property.owners.map(({ name }) => ({ name })),
    longLeaseHolders:
      property.longLeaseHolders &&
      property.longLeaseHolders.map(({ name }) => ({ name })),
    leasingCompany: property.leasingCompany?.name
      ? { name: property.leasingCompany?.name }
      : null,
    architectCompany: property.architectCompany?.name
      ? { name: property.architectCompany?.name }
      : null,
    developmentCompanies:
      property.developmentCompanies &&
      property.developmentCompanies.map(({ name }) => ({
        name,
      })),
    managementCompany: property.managementCompany?.name
      ? { name: property.managementCompany?.name }
      : null,
    operatorCompany: property.operatorCompany?.name
      ? { name: property.operatorCompany?.name }
      : null,
    amenities:
      property.amenities && property.amenities.map(({ name }) => ({ name })),
    expense: property.expense && {
      tax: property.expense.tax,
      operatingExpenses: property.expense.operatingExpenses,
      taxAndOpex: property.expense.taxAndOpex,
      year: property.expense.year,
      taxAndOpexChangedManually: property.expense.taxAndOpexChangedManually,
      timeMeasurement: property.expense.timeMeasurement,
    },
    comments: property.comments?.filter(c => !!c.text?.trim()),
    description: property?.description || '',
    latitude: property?.latitude,
    longitude: property?.longitude,
    latitudeDisplay: property?.latitudeDisplay,
    longitudeDisplay: property?.longitudeDisplay,
    uarn: property?.uarn,
    currentYearTaxAssessment: property?.currentYearTaxAssessment,
    siteNumber: property?.siteNumber,
    siteNumberProviderCompany: property.siteNumberProviderCompany?.name
      ? { name: property.siteNumberProviderCompany?.name }
      : null,
    breeam: property?.breeam,
    energyPerformanceCertificate: property?.energyPerformanceCertificate,
    fitwell: property?.fitwell,
    well: property?.well,
    planningStatus: property?.planningStatus,
    numberOfElevators: property?.numberOfElevators,
    includeInStats: property?.includeInStats,
    thirdPartyId: property?.thirdPartyId,
    // Do not submit the measurementSystem
    measurementSystem: undefined,
    ownerOccupied: property.ownerOccupied
      ? { name: property.ownerOccupied?.name }
      : null,
    buildDate: property.buildDate,
    builtYear: property.buildDate
      ? dayjs(property.buildDate)
          .utc()
          .year()
      : null,
    brokeGroundDate: property.brokeGroundDate,
    demolishedConverted: property.demolishedConverted,
    preLeasedCustomSf: property?.preLeasedCustomSf
      ? Number(removeCommaFromNumber(String(property?.preLeasedCustomSf)))
      : null,
    preLeasedCustomSm: property?.preLeasedCustomSm
      ? Number(removeCommaFromNumber(String(property?.preLeasedCustomSm)))
      : null,
    preLeasedCustomPct: property?.preLeasedCustomPct
      ? +property?.preLeasedCustomPct
      : null,
    zoning: property?.zoning,
    siteSize: property?.siteSize ? Number(property?.siteSize) : undefined,
    siteSizeHectares: property?.siteSizeHectares,
    siteSizeMt: property?.siteSizeMt,
    siteSizeSf: property?.siteSizeSf,
  };

  if (isOfficeBuilding(property)) {
    payload.office = getOfficePayload(property);
  }
  if (isLifeScienceBuilding(property)) {
    payload.lifeScience = getLifeSciencePayload(property);
  }

  if (isDataCenterBuilding(property)) {
    payload.dataCenter = getDataCenterPayload(property);

    if (
      payload?.dataCenter.dataCenterBuildOutType?.name !==
      DataCenterBuildType.RETROFIT
    ) {
      delete payload?.dataCenter?.retrofitYear;
    }
  }

  if (isIndustrialBuilding(property)) {
    payload.industrial = getIndustrialPayload(property);
    payload.parkingSpots = property?.parkingSpots || null;
    payload.siteSize = property.siteSize;
    payload.siteSizeHectares = property.siteSizeHectares;
  }

  if (isMultifamilyBuilding(property)) {
    payload.multifamily = getMultifamilyPayload(property);
  }

  if (isLandAndDevelopmentBuilding(property)) {
    payload = { ...payload };
  }

  if (property.propertyType?.name === PropertyTypes.healthcare) {
    payload.healthcare = {
      numBeds: property.healthcare?.numBeds,
      providerCompanies: property.healthcare?.providerCompanies?.map(
        ({ name }) => ({ name }),
      ),
    };
  }

  if (isSqmMeasurement) {
    delete payload?.buildingSize;
    delete payload?.officeSize;
    delete payload?.warehouseSize;
    delete payload?.preLeasedSf;
    delete payload?.preLeasedCustomSf;
    delete payload.industrial?.hookHeight;
  } else {
    delete payload?.buildingSizeMt;
    delete payload?.officeSizeMt;
    delete payload?.warehouseSizeMt;
    delete payload?.preLeasedSm;
    delete payload?.preLeasedCustomSm;
    delete payload.industrial?.hookHeightMt;
  }

  return removeUndefinedAndTypenames(payload);
};
