import React from 'react';
import ComparativeCard from 'components/PropertyProfile/Sections/Comparative/ComparativeCard';
import { Column, ComparativeCardTheme } from '../constants';
import { useQuery } from '@apollo/client';
import { SortDirections } from 'constants/sortDirections';
import SpinnerLoader from 'components/SpinnerLoader';
import { ISearchResult } from 'interfaces/ISearchResult';
import { ColorNames } from 'constants/colorNames';
import styles from 'components/PropertyProfile/Sections/Comparative/ComparativeColumns/ComparativeColumns.module.scss';
import classnames from 'classnames';
import { SEARCH_SALES_QUERY } from 'graphql/sales';
import ISale from 'interfaces/ISale';
import SaleCard from 'components/Sales/SaleCard';
import { SaleField } from 'components/Sales/fields';
import MissingCompData from 'components/MissingCompData';

const RecentSaleColumn: React.FC<Column> = ({ property, columnTheme }) => {
  const isMainColumn = columnTheme === ComparativeCardTheme.main;

  const { data, loading } = useQuery<ISearchResult<ISale>>(SEARCH_SALES_QUERY, {
    variables: {
      search: {
        filter: { propertyId: property.id },
        order: { field: 'date', direction: SortDirections.desc },
        page: { page: 1, size: 1 },
      },
      fetchPolicy: 'cache-first',
    },
  });

  const sale = data?.sales?.results?.[0] || null;

  const renderSale = () => {
    if (loading) return null;

    return sale?.id ? (
      <SaleCard
        tagBackgroundColor={
          isMainColumn ? ColorNames.ayGrey8Color : ColorNames.ayWhiteColor
        }
        item={sale!}
        size={'extra-small'}
        fieldsToShow={[
          SaleField.PricePerSF,
          SaleField.Price,
          SaleField.CapRateFormatted,
          SaleField.Floor,
          SaleField.Suite,
          SaleField.AcquisitionType,
        ]}
      />
    ) : (
      <div className={classnames(styles['missing-data-container'])}>
        <MissingCompData type="sale" property={property} />
      </div>
    );
  };

  return (
    <ComparativeCard theme={columnTheme}>
      {loading && (
        <div className={styles['loading-content']}>
          <SpinnerLoader background={ColorNames.ayTotalBlackColor} />
        </div>
      )}

      {renderSale()}
    </ComparativeCard>
  );
};

export default RecentSaleColumn;
