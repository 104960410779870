import { gql } from '@apollo/client';
import { AVAILABILITY_DATA } from './fragments';

export const GET_AVAILABILITIES_QUERY = gql`
  query getAvailabilities($search: AvailabilitySearchInput) {
    availabilities(search: $search) {
      results {
        id
        property {
          id
          name
          primaryAddress
          displayAddress
          coverMediaUrl
          coverMediaType
          country
          latitude
          longitude
          latitudeDisplay
          longitudeDisplay
          market {
            id
            name
          }
          measurementSystem
          country
          propertyType {
            id
            name
          }
          submarket {
            id
            name
          }
          micromarket {
            id
            name
          }
          propertyClass {
            id
            name
          }
          status {
            id
            name
          }
        }
        spaceUse {
          id
          name
        }
        landlordBrokerCompany {
          id
          name
        }
        askingRentMin
        askingRentMax
        timeMeasurement
        askingRentType
        suite
        leaseType
        fullFloor
        floor
        availableSpaceMin
        availableSpaceMax
        availableSpaceMinMt
        availableSpaceMaxMt
        floorContiguousSpace
        floorContiguousSpaceMt
        buildingContiguousSpace
        buildingContiguousSpaceMt
        landlordBrokerContacts
        propertySetId
        comments
        status
        occupancy
        occupancyDate
        occupancyStatusType {
          id
          name
        }
        term
        fileId
        freeRentType
        freeRentMonths
        tenantImprovementAllowance
        officeSf
        officeSm
        typicalClearHeightMin
        typicalClearHeightMax
        typicalClearHeightMinMt
        typicalClearHeightMaxMt
        loadingDocks
        driveInDoors
        taxAndOpex
        media {
          id
          url
        }
      }
    }
  }
`;

export const GET_AVAILABILITY_QUERY = gql`
  query getAvailability($id: ID!, $providerId: Int) {
    availability(id: $id, providerId: $providerId) {
      ...availabilityData
    }
  }
  ${AVAILABILITY_DATA}
`;

export const GET_AVAILABILITY_FLOORPLANS_QUERY = gql`
  query documents($availabilityId: Int!, $propertyId: Int!) {
    documents(availabilityId: $availabilityId, propertyId: $propertyId) {
      id
      name
      url
      category {
        name
      }
    }
  }
`;

export const OCCUPANCY_STATUS_TYPES = gql`
  query occupancyStatusTypes {
    occupancyStatusTypes {
      id
      name
    }
  }
`;
