import { gql } from '@apollo/client';
import { MEDIA_FRAGMENT } from './fragments';

export const SET_COVER_MEDIA_MUTATION = gql`
  mutation setCoverMedia($mediaId: Int!) {
    setCoverMedia(mediaId: $mediaId) {
      ...mediaData
    }
  }
  ${MEDIA_FRAGMENT}
`;

export const SET_MEDIA_CATEGORY_MUTATION = gql`
  mutation setMediaCategory($mediaId: Int!, $categoryId: Int) {
    setMediaCategory(mediaId: $mediaId, categoryId: $categoryId) {
      ...mediaData
    }
  }
  ${MEDIA_FRAGMENT}
`;

export const SET_MEDIA_AMENITY_MUTATION = gql`
  mutation setMediaAmenity($mediaId: Int!, $subAmenityId: Int!) {
    setMediaAmenity(mediaId: $mediaId, subAmenityId: $subAmenityId) {
      ...mediaData
    }
  }
  ${MEDIA_FRAGMENT}
`;

export const CREATE_MEDIA_MUTATION = gql`
  mutation createMedia(
    $file: Upload!
    $propertyId: Int
    $companyId: Int
    $submarketInput: SubmarketMediaInput
    $scoopId: Int
    $availabilityId: Int
  ) {
    createMedia(
      file: $file
      propertyId: $propertyId
      companyId: $companyId
      submarketInput: $submarketInput
      scoopId: $scoopId
      availabilityId: $availabilityId
    ) {
      ...mediaData
    }
  }
  ${MEDIA_FRAGMENT}
`;

export const DELETE_MEDIA_MUTATION = gql`
  mutation deleteMedia($mediaId: Int!) {
    deleteMedia(mediaId: $mediaId)
  }
`;

export const SET_MEDIA_NOTES = gql`
  mutation setMediaNotes($mediaId: Int!, $notes: String) {
    setMediaNotes(mediaId: $mediaId, notes: $notes) {
      ...mediaData
    }
  }
  ${MEDIA_FRAGMENT}
`;
