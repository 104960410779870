import ISale from 'interfaces/ISale';
import {
  convertDecimalToFloat,
  formatNumberPercentage,
  formatNumberWithCommas,
  formatPunctuationNumber,
} from 'utils/formatters/number';
import { SaleTypes } from 'constants/saleTypes';
import { isNil } from 'lodash';
import { getCurrencySymbol } from 'utils/formatters/currency';
import {
  getFieldValueForProperty,
  getFieldValueForSale,
  getUnitOfMeasurementForProperty,
} from 'utils/unitsOfMeasurement';
import {
  ModelsWithUnitsOfMeasurement,
  UnitOfMeasurement,
  UnitOfMeasurementCode,
  UNITS_MEASUREMENT_AREA,
} from 'constants/unitOfMeasurement';
import { isLongLeaseHolder } from 'utils/sales';
import { translateText, getI18nLanguageForMeasurementSystem } from 'utils/i18n';
import {
  I18N_PLATFORM_COMMON_WORD_PATH,
  I18N_AVANT_PROPERTY_SALES_LABEL_PATH,
  I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH,
  I18N_AVANT_PLATFORM_LEASE_LABEL_PATH,
} from 'constants/i18n';
import { formatDate } from 'utils/formatters/date';

export enum SaleField {
  AcquisitionType,
  BuyersBrokers,
  CapRate,
  CapRateFormatted,
  Floor,
  InterestPercent,
  InterestPercentFormatted,
  NumberOfBuildings,
  Occupancy,
  Price,
  PricePerSF,
  SellersBrokers,
  Sellers,
  Size,
  Suite,
  TenureType,
  Date,
  AssetProfile,
  WaultToExpiry,
  WaultToBreakDate,
  TotalRent,
  Conversion,
}

export const getFormattedPercent = (
  value?: number,
  suffix?: string,
  allowZero?: boolean,
) => {
  const isValidPositiveNumber = !!value && value > 0 && value <= 1;
  const isValidNumber = typeof value === 'number';
  if (!isValidPositiveNumber && !allowZero) return null;
  if (allowZero && !isValidNumber) return null;

  const valueFormatted = `${convertDecimalToFloat(value!, 1)}%`;

  return suffix ? `${valueFormatted} ${suffix}` : valueFormatted;
};

const getPortfolioLabel = (sale: ISale) => {
  const { numberOfBuildings } = sale;
  const hasNumberOfBuildings =
    !isNil(numberOfBuildings) && numberOfBuildings! > 0;

  return hasNumberOfBuildings
    ? `${numberOfBuildings}-${translateText(
        `${I18N_PLATFORM_COMMON_WORD_PATH}.propertyPortfolio`,
      )}`
    : `${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.portfolio`)}`;
};

export const getSaleFormattedFields = (
  sale: ISale,
  field: SaleField,
): string | null => {
  const propertyI18nLanguage = getI18nLanguageForMeasurementSystem(
    sale.country?.code ||
      sale.property?.propertyCountry?.code ||
      sale.property?.measurementSystem,
  );
  const unitOfMeasurement = getUnitOfMeasurementForProperty(
    'buildingSize',
    ModelsWithUnitsOfMeasurement.Property,
    sale.country?.code ||
      sale.property?.propertyCountry?.code ||
      sale.property?.measurementSystem,
  );

  const leaseholdName = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.landLease`,
    { lng: propertyI18nLanguage },
  );

  const isUkSale = sale?.measurementSystem === UnitOfMeasurementCode.UK;
  const currency = getCurrencySymbol(
    sale.country?.currencyCode ||
      sale.property?.propertyCountry?.currencyCode ||
      sale.property?.measurementSystem,
  );

  switch (field) {
    case SaleField.Date:
      return formatDate(sale.date, 'MMM YYYY');
    case SaleField.AssetProfile:
      return sale?.assetProfile?.name || null;
    case SaleField.AcquisitionType: {
      if (sale.type?.name === SaleTypes.acquisition) return null;
      return sale?.type?.name;
    }
    case SaleField.Price:
      return sale.price
        ? `${currency}${formatPunctuationNumber(sale.price)}`
        : null;
    case SaleField.PricePerSF:
      const isMultifamily = sale?.property?.propertyType?.id === 4;
      const measurement = isMultifamily
        ? UnitOfMeasurement.pu
        : UNITS_MEASUREMENT_AREA[unitOfMeasurement];
      const value = isMultifamily ? sale?.pricePerUnit : sale?.pricePerSF;
      return value
        ? `${currency}${formatPunctuationNumber(value)} ${measurement}`
        : null;

    case SaleField.Conversion:
      if (!sale.isConvertedSale) return null;
      return translateText(
        'avantPlatform.attributes.property.label.conversion',
      );

    case SaleField.Sellers:
      return sale.sellers ? sale.sellers.map(s => s.name!).join(', ') : null;
    case SaleField.CapRate:
      return sale.capRate
        ? `${formatNumberPercentage(convertDecimalToFloat(sale.capRate), 1)}`
        : null;
    case SaleField.CapRateFormatted:
      return sale.capRate
        ? `${formatNumberPercentage(
            convertDecimalToFloat(sale.capRate),
            1,
          )} ${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.capRate`)}`
        : null;
    case SaleField.BuyersBrokers:
      return sale.buyersBrokers ? `${sale.buyersBrokers}` : null;
    case SaleField.SellersBrokers:
      return sale.sellersBrokers ? `${sale.sellersBrokers}` : null;
    case SaleField.InterestPercent:
      return getFormattedPercent(sale.interestPercent);
    case SaleField.InterestPercentFormatted:
      return getFormattedPercent(
        sale.interestPercent,
        `${translateText(
          `${I18N_AVANT_PROPERTY_SALES_LABEL_PATH}.partialInterest`,
        )}`,
      );
    case SaleField.WaultToExpiry:
      return sale?.waultToExpiry
        ? `${sale?.waultToExpiry} ${
            isUkSale
              ? translateText(
                  `${I18N_AVANT_PROPERTY_SALES_LABEL_PATH}.waultToExpiry`,
                )
              : translateText(`${I18N_AVANT_PROPERTY_SALES_LABEL_PATH}.walt`)
          }`
        : null;

    case SaleField.WaultToBreakDate:
      return sale?.waultToBreakDate
        ? `${sale?.waultToBreakDate} ${translateText(
            `${I18N_AVANT_PROPERTY_SALES_LABEL_PATH}.waultToBreakDate`,
          )}`
        : null;
    case SaleField.TotalRent:
      return sale?.totalRent
        ? `${currency} ${formatNumberWithCommas(
            sale?.totalRent,
          )} ${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.totalRent`)}`
        : null;
    case SaleField.Occupancy:
      return getFormattedPercent(
        sale.occupancy,
        `${translateText(
          `${I18N_AVANT_PLATFORM_PROPERTY_LABEL_PATH}.occupancy`,
        )}`,
      );
    case SaleField.Suite:
      return sale.suite
        ? `${translateText(`${I18N_AVANT_PLATFORM_LEASE_LABEL_PATH}.suite`)} ${
            sale.suite
          }`
        : null;
    case SaleField.Floor:
      return sale.floor
        ? `${translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.floor`)} ${
            sale.floor
          }`
        : null;
    case SaleField.NumberOfBuildings:
      return sale.partOfPortfolio ? getPortfolioLabel(sale) : null;
    case SaleField.Size:
      const buildingSize = getFieldValueForProperty(
        'buildingSize',
        sale.property || {},
      );
      const condoSize = getFieldValueForSale('sf', sale);
      const size =
        sale.type?.name === SaleTypes.condo ? condoSize : buildingSize;

      return size
        ? `${formatPunctuationNumber(size)} ${unitOfMeasurement}`
        : null;
    case SaleField.TenureType:
      return isLongLeaseHolder(sale) ? leaseholdName : null;
    default:
      return null;
  }
};
