import React, { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import FormControl from 'components/FormControl';
import {
  I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH,
  I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH,
  I18N_PLATFORM_COMMON_WORD_PATH,
} from 'constants/i18n';
import { IdName } from 'interfaces/IdName';
import ChoiceInput from 'components/Inputs/ChoiceInput';
import { GET_SALE_TYPES } from 'graphql/sales';
import { isCondoSale, isLongLeaseHolder } from 'utils/sales';
import TextInput from 'components/Inputs/TextInput';
import {
  REGEX_INTEGERS,
  REGEX_THOUSAND_SEP_WITH_DECIMALS,
} from 'constants/regex';
import {
  getFieldNameForSale,
  getFieldValueForSale,
} from 'utils/unitsOfMeasurement';
import { IProperty } from 'interfaces/IProperty';
import DateInput from 'components/Inputs/DateInput';
import { authContext } from 'contexts/AuthContext';
import { LanguageEnum } from 'constants/languages';
import CurrencyInput from 'components/Inputs/CurrencyInput';
import { SaleTenureTypeEnum } from 'interfaces/ISale';
import { translateText, getI18nLanguageForProperty } from 'utils/i18n';
import UnitOfMeasurementLegend from 'components/UnitOfMeasurementLegend';
import { ASSET_PROFILE_OPTIONS, TENURE_TYPE_OPTIONS } from '../staticData';
import styles from '../../FormSection.module.scss';
import { SaleFormSectionProps } from '..';
import { YES_NO_OPTIONS } from 'constants/commonOptions';

type GetSaleTypesResult = {
  saleTypes: IdName[];
};

interface Props extends SaleFormSectionProps {
  saleCurrencySymbol: string;
  saleProperty?: IProperty;
  unitOfMeasurement: string;
  updatePricePsf: (
    salePrice?: number,
    saleType?: IdName,
    condoSize?: number,
  ) => void;
}

const SaleTypes: React.FC<Props> = ({
  saleCurrencySymbol,
  saleInput,
  saleProperty,
  unitOfMeasurement,
  updatePricePsf,
  updateSaleProperty,
}) => {
  const { user } = useContext(authContext);
  const propertyI18nLanguage = getI18nLanguageForProperty(saleProperty);
  const { data: getSaleTypes } = useQuery<GetSaleTypesResult>(GET_SALE_TYPES);

  const saleTypes = useMemo(() => {
    const isUkLanguage = user.language === LanguageEnum.EN_GB;
    const initialSaleTypes = getSaleTypes?.saleTypes ?? [];

    return initialSaleTypes?.map(t => {
      return isUkLanguage && t.name === 'Condo'
        ? { ...t, name: 'Sale of Part' }
        : t;
    });
  }, [getSaleTypes?.saleTypes, user.language]);

  const leaseholdName = translateText(
    `${I18N_PLATFORM_COMMON_WORD_PATH}.landLease`,
    { lng: propertyI18nLanguage },
  );

  const updateSaleType = (item?: IdName) => {
    updateSaleProperty('type', item);
    updatePricePsf(saleInput.price, item);
  };

  const updateCondoSize = (value: string) => {
    updateSaleProperty(
      getFieldNameForSale(
        'sf',
        saleInput,
        saleProperty?.propertyCountry?.code || saleProperty?.measurementSystem,
      ),
      value,
    );
    updatePricePsf(saleInput.price, saleInput.type, +value);
  };

  const updateTenureType = (value?: IdName) => {
    updateSaleProperty('tenureType', value?.id);

    if (value?.id !== SaleTenureTypeEnum.LONG_LEASEHOLD) {
      updateSaleProperty('longLeaseHoldExpiry', undefined);
      updateSaleProperty('longLeaseHoldGroundRent', undefined);
      updateSaleProperty('longLeaseHoldServiceCharge', undefined);
      updateSaleProperty('longLeaseHoldAdminCharge', undefined);
    }
  };

  const opportunisticOption = ASSET_PROFILE_OPTIONS.find(i => i.id === 2)!;
  const getAssetProfileOptions = () =>
    saleInput?.isConvertedSale ? [opportunisticOption] : ASSET_PROFILE_OPTIONS;

  return (
    <>
      <FormControl
        label={translateText(`${I18N_PLATFORM_COMMON_WORD_PATH}.landLease`, {
          lng: propertyI18nLanguage,
        })}
        wrapperClassName={styles['form-row']}
      >
        <div className={styles['wrapper-radio-container']}>
          <ChoiceInput
            selectedItem={{
              id: saleInput.tenureType,
            }}
            data={TENURE_TYPE_OPTIONS}
            containerWrapperClassName={styles['radio-input-container']}
            itemWrapperClassName={styles['button-input-item']}
            onChange={updateTenureType}
          />
        </div>
      </FormControl>

      {isLongLeaseHolder(saleInput) && (
        <>
          <FormControl
            label={translateText('avantProperties.labels.common.leaseExpiry', {
              leaseholdName: leaseholdName,
            })}
            wrapperClassName={styles['form-row']}
          >
            <DateInput
              id={'sale-long-leasehold-expiry'}
              value={saleInput.longLeaseHoldExpiry}
              onChange={value =>
                updateSaleProperty('longLeaseHoldExpiry', value)
              }
            />
          </FormControl>

          <FormControl
            label={translateText(
              'avantProperties.labels.common.leaseGroundRent',
              {
                leaseholdName: leaseholdName,
              },
            )}
            wrapperClassName={styles['form-row']}
          >
            <CurrencyInput
              value={saleInput.longLeaseHoldGroundRent}
              currencySymbol={saleCurrencySymbol}
              placeholder={translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.enter`,
              )}
              id={'sale-long-leasehold-ground-rent'}
              onChange={value =>
                updateSaleProperty('longLeaseHoldGroundRent', value)
              }
              pattern={REGEX_THOUSAND_SEP_WITH_DECIMALS}
              useDefaultCurrencyFormatter
            />
          </FormControl>

          <FormControl
            label={translateText(
              'avantProperties.labels.common.leaseServiceCharge',
              {
                leaseholdName: leaseholdName,
              },
            )}
            wrapperClassName={styles['form-row']}
          >
            <CurrencyInput
              value={saleInput.longLeaseHoldServiceCharge}
              currencySymbol={saleCurrencySymbol}
              placeholder={translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.enter`,
              )}
              id={'sale-long-leasehold-service-charge'}
              onChange={value =>
                updateSaleProperty('longLeaseHoldServiceCharge', value)
              }
              pattern={REGEX_THOUSAND_SEP_WITH_DECIMALS}
              useDefaultCurrencyFormatter
            />
          </FormControl>

          <FormControl
            label={translateText(
              'avantProperties.labels.common.leaseAdminCharge',
              {
                leaseholdName: leaseholdName,
              },
            )}
            wrapperClassName={styles['form-row']}
          >
            <CurrencyInput
              value={saleInput.longLeaseHoldAdminCharge}
              currencySymbol={saleCurrencySymbol}
              placeholder={translateText(
                `${I18N_PLATFORM_COMMON_WORD_PATH}.enter`,
              )}
              id={'sale-long-leasehold-admin-charge'}
              onChange={value =>
                updateSaleProperty('longLeaseHoldAdminCharge', value)
              }
              pattern={REGEX_THOUSAND_SEP_WITH_DECIMALS}
              useDefaultCurrencyFormatter
            />
          </FormControl>
        </>
      )}

      <FormControl
        label={translateText(
          'avantProperties.labels.common.buildingConversion',
        )}
        wrapperClassName={styles['form-row']}
      >
        <div className={styles['wrapper-radio-container']}>
          <ChoiceInput
            labelFieldName="id"
            selectedItem={{
              name: saleInput.isConvertedSale,
            }}
            data={YES_NO_OPTIONS}
            containerWrapperClassName={styles['radio-input-container']}
            itemWrapperClassName={styles['button-input-item']}
            onChange={v => {
              updateSaleProperty(
                'assetProfile',
                v?.name ? opportunisticOption : null,
              );
              updateSaleProperty('isConvertedSale', v?.name);
            }}
          />
        </div>
      </FormControl>
      <FormControl
        label={translateText('avantProperties.labels.common.assetProfile')}
        wrapperClassName={styles['form-row']}
      >
        <div className={styles['wrapper-radio-container']}>
          <ChoiceInput
            selectedItem={saleInput.assetProfile}
            data={getAssetProfileOptions()}
            containerWrapperClassName={styles['radio-input-container']}
            itemWrapperClassName={styles['button-input-item']}
            onChange={item => updateSaleProperty('assetProfile', item)}
          />
        </div>
      </FormControl>
      <FormControl
        label={translateText('avantProperties.labels.common.saleType')}
        wrapperClassName={styles['form-row']}
      >
        <ChoiceInput
          selectedItem={saleInput.type}
          data={saleTypes}
          containerWrapperClassName={styles['radio-input-container']}
          onChange={updateSaleType}
        />
      </FormControl>

      {isCondoSale(saleInput) && (
        <>
          <FormControl
            label={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.condoFloor`,
            )}
            wrapperClassName={styles['form-row']}
            required
          >
            <TextInput
              id={'sale-condo-floor'}
              value={saleInput.floor}
              placeholder={translateText(
                'avantProperties.labels.common.enterFloor',
              )}
              onChange={value => updateSaleProperty('floor', value)}
            />
          </FormControl>
          <FormControl
            label={translateText(
              `${I18N_PLATFORM_COMMON_WORD_PATH}.condoSuite`,
            )}
            wrapperClassName={styles['form-row']}
          >
            <TextInput
              id={'sale-condo-suite'}
              value={saleInput.suite}
              placeholder={translateText(
                `${I18N_AVANT_PLATFORM_LEASE_PROMPT_PATH}.enterSuite`,
              )}
              onChange={value => updateSaleProperty('suite', value)}
            />
          </FormControl>
          <FormControl
            label={translateText('avantProperties.labels.common.condoSize')}
            wrapperClassName={styles['form-row']}
            required
          >
            <TextInput
              id={'sale-condo-size'}
              pattern={REGEX_INTEGERS}
              value={getFieldValueForSale(
                'sf',
                saleInput,
                saleProperty?.propertyCountry?.code ||
                  saleProperty?.measurementSystem,
              )}
              placeholder={translateText(
                `${I18N_AVANT_PLATFORM_PROPERTY_PROMPT_PATH}.enterSize`,
              )}
              onChange={updateCondoSize}
            />
            <UnitOfMeasurementLegend
              unitOfMeasurement={unitOfMeasurement}
              shouldConvert={false}
            />
          </FormControl>
        </>
      )}
    </>
  );
};

export default SaleTypes;
